import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram.svg';
import { ReactComponent as YoutubeIcon } from 'assets/icons/youtube.svg';

const LandingFooter = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full bg-[#3D3D3D] py-10 sm:py-[64px]">
      <div className="max-w-[1200px] w-[90%] flex flex-col justify-start gap-6 mx-auto sm:justify-between lg:flex-row">
        <a href="/" className="max-w-[166px] h-auto">
          <img src={require('assets/images/logo-white.png')} alt="logo" />
        </a>

        <div className="flex flex-col justify-between gap-6 sm:flex-row sm:gap-14">
          <div className="flex flex-col gap-4">
            <p className="text-lg text-white font-bold">{t('landing.nav.links')}</p>
            <div className="flex flex-col gap-2">
              <a
                href="#study-programs"
                className="text-sm font-normal text-white transition hover:text-white hover:-translate-y-[2px]"
              >
                {t('landing.nav.courses')}
              </a>
              <a
                href="#about"
                className="text-sm font-normal text-white transition hover:text-white hover:-translate-y-[2px]"
              >
                {t('landing.nav.about')}
              </a>
              <a
                href="#appointment"
                className="text-sm font-normal text-white transition hover:text-white hover:-translate-y-[2px]"
              >
                {t('landing.nav.callback')}
              </a>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <p className="text-lg text-white font-bold">{t('landing.nav.socials')}</p>
            <div className="flex flex-col gap-2">
              <a
                href="https://www.instagram.com"
                className="flex flex-row gap-2 text-sm font-normal text-white transition hover:text-white hover:-translate-y-[2px]"
              >
                <InstagramIcon /> Instagram
              </a>
              <a
                href="https://www.facebook.com"
                className="flex flex-row gap-2 text-sm font-normal text-white transition hover:text-white hover:-translate-y-[2px]"
              >
                <FacebookIcon /> Facebook
              </a>
              <a
                href="https://www.youtube.com"
                className="flex flex-row gap-2 text-sm font-normal text-white transition hover:text-white hover:-translate-y-[2px]"
              >
                <YoutubeIcon /> Youtube
              </a>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <p className="text-lg text-white font-bold">{t('landing.nav.contacts')}</p>
            <div className="flex flex-col gap-2">
              <p className="text-sm font-normal text-white transition">
                {t('landing.nav.address')}
              </p>
              <a
                href="mailto:example@example.kz"
                className="text-sm font-normal text-white transition hover:text-white hover:-translate-y-[2px]"
              >
                example@example.kz
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingFooter;
