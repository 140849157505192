export default {
  nav: {
    links: 'Сілтемелер',
    home: 'Басты бет',
    about: 'Біз туралы',
    aboutPlatform: 'Платформа туралы',
    apps: 'қолданбалар',
    analytics: 'аналитика',
    interface: 'интерфейс',
    programs: 'Оқу бағдарламалары',
    application: 'Курсқа жазылу',
    login: 'Жүйеге кіру',
    courses: 'Курстар',
    callback: 'Қайта қоңырау шалу',
    socials: 'Әлеуметтік желілер',
    contacts: 'Контактілер',
    address: 'Астана қаласы, Мәңгілік ел көшесі, 777',
  },
  actions: {
    сooperation: 'ынтымақтастық',
    try: 'тырысу',
    moreReasons: 'Артықшылықтар туралы көбірек білу',
    allCourses: 'Курстардың толық каталогы',
  },
  hero: {
    try: 'Демо нұсқасын қолданып көріңіз',
    titleTop: 'Оқытуды тиімді басқаруға',
    titleBottom: 'арналған инновациялық шешім',
    subtitle:
      'Тренингтер жасаңыз, дерекқор жинаңыз, төлем модулі арқылы төлемдерді қабылдаңыз, процестерді оңай және жылдам басқарыңыз',
    btn: 'тегін көріңіз',
  },
  main: {
    title: 'MP Academy-ге қош келдіңіз!',
    subtitle: 'Біз заманауи қойма кешендерінде жұмыс істеу үшін кәсіби мамандарды оқытамыз.',
    text1:
      'Бұл жай ғана Оқу орталығы емес, бұл – қазіргі заманғы қойма кешендерінде жұмыс істеу үшін мамандарды даярлауға және жаңа буын басшыларын дайындауға бағытталған стратегиялық жоба. Логистикалық саладағы кадрлар тапшылығы жағдайында біз оқыту және кәсіби өсу мүмкіндіктерін ұсынамыз, бұл нарықтың қажеттіліктеріне толық сәйкес келеді. Біздің бағдарламаларымызды логистикалық кәсіпорындардағы қазіргі қызметкерлермен бірге әзірлегенбіз және біз қойма ісі мен логистика саласының сарапшыларының құзыреттерін кеңейту үшін білімімізді береміз.',
    text2:
      'MP Academy – Mercury Solutions компаниясының стратегиялық жобасы. Mercury Solutions – Қазақстанда және Орталық Азиядағы коммерциялық жылжымайтын мүлікпен айналысатын ТОП-1 компаниялардың бірі. Біз заманауи қойма кешендерінде жұмыс істеуге дайын жаңа буын мамандарын дайындауға бағытталғанбыз.',
  },
  reasons: {
    title: 'Неліктен бізді таңдау керек?',
    reason1: {
      title: 'Оқу моделі 70-20-10',
      text1: '70% - жұмыс орнында оқу',
      text2: '20% - коучинг немесе білікті әріптестерден үйрену',
      text3: '10% - сыртқы оқыту',
    },
    reason2: {
      title: 'Кешенді тәсіл',
      text: 'Біз мамандарды тек функционалдық бөлігінде ғана емес, сонымен қатар негізгі және іскерлік дағдыларды дамытуға үйретеміз.',
    },
    reason3: {
      title: 'Икемділік',
      text1:
        'Оқу форматының кең таңдауы: онлайн, офлайн, жаттықтырушы арқылы немесе өз бетінше оқу.',
      text2: 'Оқыту құны таңдалған форматқа байланысты белгіленеді.',
    },
  },
  programs: {
    title: 'Мамандар мен басқарушыларға арналған бағдарламалар',
    subtitle: 'Курстардың сипаттамасы',
    course1: {
      title: 'Қойма ісінің негіздері курсы',
      text1: 'Оқытудың мақсаты – қойма операторларын оқыту.',
      text2: 'Ұзақтығы: 4-6 апта.',
      text3: 'Негізгі міндеттер:',
      text4: 'Процестерді түсіну.',
      text5: 'Қойманы тиімді басқару дағдылары.',
    },
    course2: {
      title: 'Миссия: Басшы',
      text1:
        'Басшыларды қызметкерлердің өмірлік циклінің барлық кезеңдерінде тиімді басқаруға дайындау.',
      text2:
        'Жаңа бастаған және персоналды басқару саласындағы білімдері мен дағдыларын кеңейткісі келетін түрлі деңгейдегі басшыларға арналған.',
      text3: 'Бағдарлама 34 мастер-кластан тұрады.',
      text4: 'Оқыту онлайн және оффлайн форматта өткізіледі.',
    },
  },
  application: {
    title: 'Өтінім қалдырыңыз',
    subtitle: 'Кері байланыс формасы.',
    callbackText: 'Барлық мәліметтерді нақтылау үшін сізбен 24 сағат ішінде хабарласамыз.',
    form: {
      name: 'Атыңыз',
      surname: 'Тегіңіз',
      selectedCourse: 'Таңдалған курс',
      submit: 'Өтінім қалдырыңыз',
    },
    messages: {
      success: 'Өтінішіңіз сәтті жіберілді',
      error: 'Қате орын алды. Қолдау қызметіне хабарласыңыз',
    },
  },
};
