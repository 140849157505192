import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import BaseImage from 'components/BaseImage';
import LMSButton from 'components/LMSButton';
import LMSPagination from 'components/LMSPagination';
import Loader from 'components/Loader';
import Title from 'modules/student/components/Title';
import { useAllCourses } from 'modules/student/hooks/all-courses';
import { useAppSelector } from 'hooks/redux';

import Footer from '../../components/LandingFooter';
import Header from '../../components/LandingHeader';

const Courses = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authToken } = useAppSelector((state) => state.auth);
  const { isLoading, courses, lastPage, currentPage, setCurrentPage } = useAllCourses();

  const goToCourseDetail = (id: number) => {
    navigate(authToken ? `/student/courses/${id}` : '/auth/login');
  };

  const badgeClass =
    'flex items-center mb-2 px-5 py-2 theme-badge text-current text-xs md:text-base rounded-full truncate overflow-hidden';

  return (
    <>
      <Header />
      <div className="w-full  px-4 pt-12 md:pt-20">
        <Title className="text-center">{t('common.allCourses')}</Title>

        <div className="flex flex-col lg:flex-row lg:space-x-6 mt-10 md:mt-16">
          <div className="flex-1 flex flex-col space-y-5">
            {isLoading && courses.length === 0 ? <Loader /> : null}
            {!isLoading && courses.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-[460px] rounded-[20px] theme-student-card">
                <p className="text-[30px] text-center leading-10 max-w-[420px] mb-14">
                  Курсы не найдены. Продолжайте поиск, чтобы найти нужный курс
                </p>
                <LMSButton>Продолжить обучение</LMSButton>
              </div>
            ) : null}

            {courses.map((course) => (
              <div
                key={course.id}
                className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-9 p-4 md:p-8 rounded-3xl theme-student-card"
              >
                <div className="relative w-full md:w-[300px] lg:w-[266px] h-[210px] lg:h-[266px] shrink-0 theme-background--grey rounded-2xl overflow-hidden">
                  <NavLink to={authToken ? `/student/courses/${course.id}` : '/auth/login'}>
                    <BaseImage image={course.imageUrl} alt={course.name} />
                  </NavLink>
                  {course.studyType === 'inner' ? (
                    <div className="absolute top-3 py-3 pl-4 pr-2 bg-green text-current text-sm rounded-r-full">
                      <p>внутренний курс</p>
                    </div>
                  ) : null}
                </div>
                <div className="flex flex-col flex-1">
                  <div className="flex flex-wrap space-x-2">
                    <p className={badgeClass}>{course.dateFrom || 'Не указан'}</p>
                    <p className={badgeClass}>{course.direction || 'Не указан'}</p>
                    <p className={badgeClass}>{course.courseType || 'Не указан'}</p>
                  </div>
                  <NavLink
                    to={authToken ? `/student/courses/${course.id}` : '/auth/login'}
                    className=" flex-1 mt-5 mb-5 lg:mt-10 text-2xl theme-text--default"
                  >
                    {course.name}
                  </NavLink>
                  <div className="flex flex-nowrap md:flex-wrap w-full mt-5 md:mt-0 md:mb-2 md:w-auto space-x-2">
                    <LMSButton
                      className="pl-2 pr-2 md:pl-4 md:pr-4"
                      onClick={() => goToCourseDetail(course.id)}
                    >
                      Перейти к курсу
                    </LMSButton>
                  </div>
                </div>
              </div>
            ))}

            <LMSPagination
              current={currentPage}
              lastPage={lastPage}
              onChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Courses;
